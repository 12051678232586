import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => {
  // allow `external` link in frontmatter for linking out from blog index page.
  const link = post.frontmatter.external || post.frontmatter.slug
  return (
    <div class="blog-post-link">
      <h2 class="blog-title">
        <Link to={link}>{post.frontmatter.title}</Link>
      </h2>
      <div class="blog-date">{post.frontmatter.date}</div>
      <div class="blog-description">{post.frontmatter.description}</div>
    </div>
  )
}

export default PostLink
